import React from 'react';
import { FormGroup, Checkbox } from "react-bootstrap";
import ShowInfo from './ShowInfo';
import TableItem from './TableItem';
import { ToResult, localStorage } from '../../../../Utils';
import { empresas, cfdi, ventas } from '../../Urls';
import Loading from './Loading';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';

import {
    schemaComprobante, 
    schemaProducto,
    CalcTotales,
    CalcTotalConcepto,
    currency,
    EmisorComprobante,
    ReceptorComprobante,
    IMG_LOGO,
    OcupaTipoCambio,
    FormatoDateTime,
    DireccionEmisor,
    ArmarClienteConReceptor,
    ShowMessage,
    schemaEmpresa,
    OcupaNotaDeCredito,
    ObtenerTipoComprobanteNotaDeCredito,
    ObtenerTipoRelacionNotadeCredito,
    ObtenerUsuarioYGrupo,
    schemaReceptor,
    TieneMismosConcepto,
    MismaEmpresaUrlYGuardada,
    ObtenerConceptosSeleccionados,
    Pasos
} from '../../Models';

import ButtonLoading from './ButtonLoading';


const INIT_FORM = {
    _RFC:'',
    _Correo:'',
    _EmpresaID:'',
    _Registrarme: false,
    _AceptoTerminos: false
};


class Comprobante extends React.Component  {
    state = {
        loadingData: true,
        empresas: [],
        empresa:{
           ...schemaEmpresa
        },
        form:{
            ...INIT_FORM
        },
        venta:{},
        comprobante: {
            ...schemaComprobante,
            _Emisor:{
                ...schemaComprobante._Emisor,
            },
            _Receptor:{
                ...schemaComprobante._Receptor,
            },
            _Conceptos: [ ]
        },
        receptorGenerico: { ...schemaReceptor },
        monedas: [],
        tiposcomprobantes: [],
        tiposrelaciones: [],
        metodospago: [],
        formaspago: [],
        productos: []
    };

    componentDidMount(){
        const empresa = localStorage.get('empresa');
        const venta = localStorage.get('venta');
        if(!this.PasoValido()) {
            window.location = "#/";
            return;
        }
        if( empresa && MismaEmpresaUrlYGuardada(empresa, this.props )){
            this.setState({ empresa, venta }, this.ObtenerReceptorDelEmisor.bind(this));
        } else if(this.TieneEmpresaName()){
            this.ObtenerEmpresa(this.ObtenerReceptorDelEmisor.bind(this));
        } else {
            window.location = "#/";
            return;
        }
        this.precargarInformacion();
    }


    PasoValido(){
        const paso_anterior = localStorage.get('paso_anterior');
        return paso_anterior === Pasos.Receptor;    
    }

    precargarInformacion(){
        const informacion = localStorage.get('informacion');
        if(informacion){
            this.setState({
                form:{
                    ...INIT_FORM,
                    ...informacion
                }
            });
        }
    }

    ObtenerEmpresa( callback = () => {} ){
        const venta = localStorage.get('venta');
        ToResult.ToResultPost( empresas.url_obtenerempresaporshortname, {}, (err, response) => {
            if (response.isValid) 
                this.setState({ empresa: response.data, venta }, callback );
            else
               window.location = "#/";
        });
    }

    ObtenerReceptorDelEmisor(){
        const { comprobante, empresa, venta } = this.state;
        const usocfdi = localStorage.get('uso_cfdi');
        const receptor = localStorage.get('receptor');
        const params = {
            _GrupoID: venta._GrupoID,
            _UserID: venta._UserID,
            _EmisorID: empresa._EmisorID,
            _RfcReceptor: venta._Cliente._RFC
        };
        
        ToResult.ToResultPost( cfdi.url_datoscfdi, params, (err, response) => {
            if (ShowMessage(response)) {
                const {
                    _RegimenesCFDI,
                    _EmisorCFDI,
                    _ProductosCFDI,
                    _TiposComprobantesCFDI,
                    _MonedasCFDI,
                    _FormasPagoCFDI,
                    _MetodosPagoCFDI,
                    _TiposRelacionesCFDI,
                    _UsosCFDI
                } = response.data;
                const _SucursalID = empresa._Sucursales.filter(suc => suc._SucursalID === venta._Empresa._Sucursal._SucursalID )[0]._SucursalEmisorID; 
                const _Emisor = EmisorComprobante( _EmisorCFDI, _SucursalID , _RegimenesCFDI );
                const _Receptor = ReceptorComprobante( receptor, _UsosCFDI );
                this.setState({ receptorGenerico: _Receptor });
                let _FormaPago = {};
                let _CantidadMayor = 0;
                venta._FormasPago.forEach(fp => {
                    if (fp._Cantidad > _CantidadMayor) {
                        _CantidadMayor = fp._Cantidad;
                        _FormaPago = fp._FormaPago;
                    }
                });
                const _Comprobante = {
                    ...comprobante,
                    _Fecha: moment(venta._FechaEmision).format( FormatoDateTime ),
                    _FormaPago,
                    _TipoComprobante: _TiposComprobantesCFDI.filter(tipo => tipo._TipoComprobanteID === comprobante._TipoComprobante._TipoComprobanteID )[0] || {},
                    _MetodoPago: _MetodosPagoCFDI.filter(metodo => metodo.c_MetodoPago === comprobante._MetodoPago.c_MetodoPago )[0] || {},
                    _Moneda: _MonedasCFDI.filter(metodo => metodo.c_Moneda === comprobante._Moneda.c_Moneda )[0] || {},
                    _Emisor,
                    _Receptor: {
                        ...comprobante._Receptor,
                        ..._Receptor,
                        _UsoCFDI: {
                            ...comprobante._Receptor._UsoCFDI,
                            ...usocfdi
                        }
                    },
                    _Conceptos:  
                        venta._Conceptos.map(( _Concepto ) => {
                            let producto  = _ProductosCFDI.filter((_Producto) => _Concepto._Producto._NoIdentificacion === _Producto._NoIdentificacion )[0];
                            let _ProdServ = {};
                            if(!producto){
                                _ProdServ = {
                                    c_ClaveProdServ: empresa._Configuracion._DatosVenta._ClaveProdServConsumo,
                                };
                            }else{
                                _ProdServ = producto._ProdServ;
                            }
                            producto = {
                                ...schemaProducto,
                                ..._Concepto._Producto,
                                _ProductoID: "",
                                ...producto,
                                _ProdServ,
                                _ValorUnitario: _Concepto._Producto._ValorUnitario
                            };
                            return {
                                ..._Concepto,
                                _Producto: producto,
                                _Traslados: {},
                                _Retenciones: {},
                                isSelected: true
                            };
                        }),
                };
                _Comprobante._Conceptos.forEach( ( _Concepto ) => {
                    _Concepto._Importe = CalcTotalConcepto( _Concepto  );
                });

                this.setState({ 
                    loadingData: false, 
                    comprobante: {
                        ..._Comprobante,
                        ...CalcTotales(ObtenerConceptosSeleccionados(_Comprobante)),
                        _AllSelected: true
                    },
                    monedas: _MonedasCFDI,
                    tiposcomprobantes: _TiposComprobantesCFDI,
                    tiposrelaciones: _TiposRelacionesCFDI,
                    metodospago: _MetodosPagoCFDI,
                    formaspago: _FormasPagoCFDI,
                    productos: _ProductosCFDI
                });
            }
        });
    }    

    TieneEmpresaName() {
        return !!this.props.match.params.empresaName;
    }

    ObtenerShortNameEmpresa(){
        return this.props.match.params.empresaName;
    }

    MismaEmpresaUrlYGuardada(empresa){
        return empresa._Configuracion._DatosVenta._ShortName === this.props.match.params.empresaName; 
    }

    UsaFacturaPorConsumo(empresa){
        return empresa._Configuracion._DatosVenta._UsaFacturacionPorConsumo; 
    }

    onClickSiguiente(e){
        e.preventDefault();
    }

    updateForm( { target } ){
        const { name, value, checked } = target;
        let { form } = this.state;
        if(target.type === "checkbox"){
            form = {
                ...form,
                [name]: checked 
            };
        } else {
            form = {
                ...form,
                [name]: value
            };
        }
        this.setState({
            form
        });
    }

    onSubmitForm(e){
        e.preventDefault();
        this.onClickTimbrarVenta();
    }

    ObtenerComprobanteRelacionado( venta , callback = () => {} ) {
        ToResult.ToResultPost( cfdi.url_obtenercfdirelacionados, {
            _ComprobantesIds: [ venta._ComprobanteID ]
        }, (err, response) => {
            if (ShowMessage(response)) {
                callback(response.data._Comprobantes);
            }
        });
    }

    ObtenerComprobanteNotaDeCreditoDelComprobanteTimbrado(comprobante, callback = () => {}){
        const { receptorGenerico, venta, tiposcomprobantes, tiposrelaciones } = this.state;
        if(!OcupaNotaDeCredito( venta )){
            callback();
			return;
        }
	
        const { 
			_Complementos, 
			_Conceptos, 
			_Descuento, 
			_Emisor, 
			_FormaPago, 
			_Impuesto, 
			_MetodoPago, 
			_Moneda, 
			_Subtotal, 
			_TipoCambio,
			_Fecha
        } = comprobante;

        let _Comprobante = {
            ...ObtenerUsuarioYGrupo( venta ),
            _TipoComprobante: { 
                ...ObtenerTipoComprobanteNotaDeCredito( tiposcomprobantes ) 
            },
            _Serie: _Emisor._Sucursal._Serie,
            _Folio: _Emisor._Sucursal._Folio,
            _Complementos,
            _Emisor, 
            _Receptor: {
                ...schemaReceptor,
                ...receptorGenerico
            }, 
            _FormaPago, 
            _MetodoPago, 
            _Moneda, 
            _TipoCambio: _TipoCambio || 0,
            _Descuento, 
            _Impuesto, 
            _Subtotal
        };

		this.ObtenerComprobanteRelacionado(venta, ( _CfdiRelacionados ) => {
            _Comprobante = {
                ..._Comprobante,
                _CfdiRelacionados: {
                    _TipoRelacion: {  ...ObtenerTipoRelacionNotadeCredito( tiposrelaciones ) },
                    _Comprobantes: _CfdiRelacionados
                },
            };
			this.ObtenerConceptoNotaDeCredito( _Comprobante, ObtenerConceptosSeleccionados({  _Conceptos  })._Conceptos , ( _NuevaNotaDeCredito ) => {
				const params = {
                    ..._NuevaNotaDeCredito,
                    ...CalcTotales(_NuevaNotaDeCredito),
					_Fecha: moment( _Fecha, FormatoDateTime ).format( FormatoDateTime )
                };
                ToResult.ToResultPost( cfdi.url_timbrar, params, ( err, response ) => {
                   // if (ShowMessage(response)) {
                    if(response.isValid) {
                        callback();
                    }
				});
			}) 
		});
    }
    
    ObtenerConceptoConsumo(_ComprobantePorConsumo, _ConceptosComprobante , callback = () => {} ) {
        const { empresa } = this.state; 
        ToResult.ToResultPost( cfdi.url_obtenerconceptoconsumo, {
            _EmisorID: _ComprobantePorConsumo._Emisor._EmisorID,
            _GrupoID: _ComprobantePorConsumo._Emisor._GrupoID,
            _ClaveProdServ: empresa._Configuracion._DatosVenta._ClaveProdServConsumo 
        }, ( err, response ) => {
            if (ShowMessage(response)) {
                _ComprobantePorConsumo._Conceptos = []; 
                const _Concepto = { 
                    _Producto: { 
                        ...schemaProducto,
                        ...response.data 
                    },
                    _Cantidad: 1
                };
                _ConceptosComprobante.forEach(( _ConceptoComprobante ) => {
                    let _EstaConcepto = false;
                    _ComprobantePorConsumo._Conceptos.forEach(( _ConceptoNdC ) => {
                        if( TieneMismosConcepto ( _ConceptoComprobante._Producto, _ConceptoNdC._Producto ) ){
                            _ConceptoNdC._Producto._ValorUnitario += _ConceptoComprobante._Producto._ValorUnitario * _ConceptoComprobante._Cantidad;  
                            _EstaConcepto = true;
                        }
                    });
                    if(!_EstaConcepto){
                        _ComprobantePorConsumo._Conceptos.push({
                            ..._Concepto,
                            _Producto: {
                                ..._Concepto._Producto,
                                _ValorUnitario: _ConceptoComprobante._Producto._ValorUnitario * _ConceptoComprobante._Cantidad,
                                _TieneImpuestos: _ConceptoComprobante._Producto._TieneImpuestos,
                                _Impuestos: _ConceptoComprobante._Producto._Impuestos
                            },
                            _Cantidad: 1,
                        });
                    }
                });
                callback(_ComprobantePorConsumo);
            }
        });
    }


    ObtenerConceptoNotaDeCredito( _NotadeCredito, _ConceptosComprobante, callback = () => {} ){
        ToResult.ToResultPost( cfdi.url_obtenerconceptonotadecredito, {
            _EmisorID:  _NotadeCredito._Emisor._EmisorID,
			_GrupoID:  _NotadeCredito._Emisor._GrupoID
        }, (err, response) => {
            if (ShowMessage(response)) {
                _NotadeCredito._Conceptos = []; 
                const _Concepto = { 
                    _Producto: { 
                        ...schemaProducto,
                        ...response.data 
                    },
                    _Cantidad: 1,
                    _Importe: 0
                };
                _ConceptosComprobante.forEach(( _ConceptoComprobante ) => {
                    let _EstaConcepto = false;
                    _NotadeCredito._Conceptos.forEach(( _ConceptoNdC ) => {
                        if( TieneMismosConcepto ( _ConceptoComprobante._Producto, _ConceptoNdC._Producto ) ){
                            _ConceptoNdC._Producto._ValorUnitario += _ConceptoComprobante._Producto._ValorUnitario * _ConceptoComprobante._Cantidad;  
                            _EstaConcepto = true;
                            _Concepto._Importe = CalcTotalConcepto( _Concepto  );
                        }
                    });
                    if(!_EstaConcepto){
                        _NotadeCredito._Conceptos.push({
                            ..._Concepto,
                            _Producto: {
                                ..._Concepto._Producto,
                                _ValorUnitario: _ConceptoComprobante._Producto._ValorUnitario * _ConceptoComprobante._Cantidad,
                                _TieneImpuestos: _ConceptoComprobante._Producto._TieneImpuestos,
                                _Impuestos: _ConceptoComprobante._Producto._Impuestos
                            },
                            _Cantidad: 1,
                            _Importe: CalcTotalConcepto( _Concepto  )
                        });
                    }
                });
                callback(_NotadeCredito);
            }
        });
		
	}

    onClickTimbrarVenta(){
        const { comprobante, empresa, venta, form } = this.state;
        let params = {
            _GrupoID: venta._GrupoID,
            _UserID: venta._UserID,
            _UserName: venta._UserName,
            _EmisorID: empresa._EmisorID,
            _Observaciones: form._Observaciones
        };

		const { 
			_CfdiRelacionados, 
			_Complementos, 
			_Conceptos, 
			_Descuento, 
			_Emisor, 
			_Folio, 
			_FormaPago, 
			_Importe, 
			_Impuesto, 
			_MetodoPago, 
			_Moneda, 
			_Receptor, 
			_Serie, 
			_Subtotal, 
			_TipoComprobante,
			_TipoCambio,
			_Fecha
        } = comprobante;
        params = { 
			...params, 
			_CfdiRelacionados,
			_Complementos,
			_Conceptos,
			_Descuento,
			_Emisor,
			_Folio,
			_FormaPago,
			_Importe,
			_Impuesto,
			_MetodoPago,
			_Moneda,
			_Receptor,
			_Serie,
			_Subtotal,
			_TipoCambio,
			_TipoComprobante,
			_Fecha: moment( _Fecha, FormatoDateTime ).format( FormatoDateTime )
        };
        if(_Conceptos.filter( concepto => concepto.isSelected ).length === 0){
            NotificationManager.warning("No se a seleccionado ningun concepto para facturar", "Advertencia");
            return;
        }
        this.setState({ loadingFacturar: true });
        this.ObtenerComprobanteNotaDeCreditoDelComprobanteTimbrado( comprobante, this.TimbrarComprobante.bind(this, params ));
    }

    TimbrarComprobante( params ){
        const { comprobante, empresa, venta } = this.state;
        ToResult.ToResultPost( cfdi.url_timbrar, params, ( err, response ) => {
            if (ShowMessage(response)){
                const Cliente = ArmarClienteConReceptor(comprobante._Receptor, venta._Vendedor);
                const comprobanteTimbrado = response.data; 
                ToResult.ToResultPost( ventas.url_generartimbre , {
                    _ComprobanteID: comprobanteTimbrado._ComprobanteID,
                    _Ventas: [ venta._VentaID ],
                    _RFC: comprobante._Receptor._DatosFiscales._RFC,
                    _EmpresaID: venta._Empresa._EmpresaID,
                    _SucursalEmisorID: comprobante._Emisor._Sucursal._SucursalID,
                    _EstatusDescripcion: comprobanteTimbrado._Estatus,
                    _Cliente: Cliente,
                    _TimbradaPorUsuario: true
                }, (err, response) => {
                    if (response.isValid){
                        this.setState({ loadingFacturar: false });
                        localStorage.set('paso_anterior', Pasos.Comprobante );
                        window.location = `#/${empresa._Configuracion._DatosVenta._ShortName}/visualizador/${comprobanteTimbrado._ComprobanteID}`;
                    } else {
                        this.setState({ loadingFacturar: false });
                    }
                }); 
            } else{
                this.setState({ loadingFacturar: false });
            }
        });
    }
    
    onClickAtras(e){
        e.preventDefault();
        window.location = `#/${this.state.empresa._Configuracion._DatosVenta._ShortName}/receptor`;
    }

    onCheckConcepto( e, index ){
        const _Conceptos = this.state.comprobante._Conceptos.map( (_Concepto, ind) => {
            if(index === ind)
                return {
                    ..._Concepto,
                    isSelected: e.target.checked
                }
            return _Concepto;
        });
        
        this.setState({
            comprobante: {
                ...this.state.comprobante,
                ...CalcTotales(ObtenerConceptosSeleccionados({ _Conceptos })),
                _Conceptos,
                _AllSelected: _Conceptos.length === ObtenerConceptosSeleccionados({_Conceptos}).length
        }});
    }

    updateAllSelected( e ){
        const _Conceptos = this.state.comprobante._Conceptos.map( (_Concepto, ind) => {
            return {
                ..._Concepto,
                isSelected: e.target.checked
            }
        });
        
        this.setState({
            comprobante: {
                ...this.state.comprobante,
                ...CalcTotales(ObtenerConceptosSeleccionados({ _Conceptos })),
                _Conceptos,
                _AllSelected: e.target.checked
        }});
    }

    onCheckPorConsumo( e ){
        const { comprobante } = this.state;
        if( comprobante._Conceptos.filter(c => c.isSelected ).length === 0){
            this.setState({
                form: {
                    ...this.state.form,
                    _ComoConsumo: this.state.form._ComoConsumo === true
                }
            });
            NotificationManager.warning("No hay concepto seleccionados para convertirlos a consumo ","Advertencia");
            return;
        }
        this.setState({
            form: {
                ...this.state.form,
                _ComoConsumo: e.target.checked
            }
        });
        
        if(e.target.checked){
            this.ObtenerConceptoConsumo( comprobante , ObtenerConceptosSeleccionados( comprobante )._Conceptos , ( _ComprobantePorConsumo ) => {
                _ComprobantePorConsumo._Conceptos.forEach( ( _Concepto ) => {
                    _Concepto._Importe = CalcTotalConcepto( _Concepto  );
                    _Concepto.isSelected = true;
                });
                this.setState({
                    comprobante: {
                        ..._ComprobantePorConsumo,
                        ...CalcTotales( _ComprobantePorConsumo ),
                    }
                });
            }) 
        } else {
            const _Conceptos = this.state.venta._Conceptos.map(( _Concepto ) => {
                let producto  = this.state.productos.filter((_Producto) => _Concepto._Producto._NoIdentificacion === _Producto._NoIdentificacion )[0];
                producto = {
                    ...schemaProducto,
                    ..._Concepto._Producto,
                    _ProductoID: "",
                    ...producto,
                    _ValorUnitario: _Concepto._Producto._ValorUnitario
                };
                return {
                    ..._Concepto,
                    _Producto: producto,
                    _Traslados: {},
                    _Retenciones: {},
                    isSelected: true
                };
            });

            _Conceptos.forEach( ( _Concepto ) => {
                _Concepto._Importe = CalcTotalConcepto( _Concepto  );
            });

            this.setState({ 
                comprobante: {
                    ...comprobante,
                    _Conceptos,
                    ...CalcTotales(ObtenerConceptosSeleccionados({ _Conceptos }))
                },
            });
        }
    }
    

    render(){
        const { form, comprobante, empresa, loadingData, loadingFacturar } = this.state;
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 slide--action">
                <form className="mb-0" onSubmit={ this.onSubmitForm.bind(this) }>
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2">
                            <img className="img-responsive" src={ empresa._Logo || IMG_LOGO } alt=""/>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10">
                            <h5>Paso 3: Verificar datos.</h5>
                            <div className="alert alert-info">
                                <span className="bold">
                                    Si hay un error en tus datos fiscales presiona el botón Anterior y modifica los datos.
                                    <br/>
                                    Si los datos son correctos, haz clic en FACTURAR.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 well" style={{color:"#555"}}>
                            <Loading loading={loadingData}>
                                <h5 style={{margin:'0 0 5px', fontSize: '1.5em'}}>Comprobante</h5>
                                <ShowInfo title="Tipo Comprobante">{`${comprobante._TipoComprobante._TipoComprobanteID} - ${comprobante._TipoComprobante._Nombre}`}</ShowInfo>
                                <ShowInfo title="Moneda">{`${comprobante._Moneda.c_Moneda} - ${comprobante._Moneda.descripcion}`}</ShowInfo>
                                {  OcupaTipoCambio(comprobante._Moneda)?
                                    <ShowInfo title="Tipo de Cambio"> { currency( comprobante._TipoCambio ) }</ShowInfo>
                                : false }
                                <ShowInfo title="Forma de Pago">{`${comprobante._FormaPago.c_FormaPago} - ${comprobante._FormaPago.descripcion}`}</ShowInfo>
                                <ShowInfo title="Metodo de Pago">{`${comprobante._MetodoPago.c_MetodoPago} - ${comprobante._MetodoPago.descripcion}`}</ShowInfo>
                                <ShowInfo title="Fecha">{ moment(comprobante._Fecha).format( FormatoDateTime ) }</ShowInfo>
                                <br/>
                                <h5 style={{margin:'0 0 5px', fontSize: '1.5em'}}>Emisor</h5>
                                <ShowInfo title="Razon Social">{ comprobante._Emisor._Nombre }</ShowInfo>
                                <ShowInfo title="Direción">{ DireccionEmisor(comprobante._Emisor )}</ShowInfo>
                                <ShowInfo title="Lugar de Expedición">{comprobante._Emisor._Sucursal._Nombre}</ShowInfo>
                                <ShowInfo title="Regimen Fiscal">{`${comprobante._Emisor._RegimenFiscal.c_RegimenFiscal} - ${comprobante._Emisor._RegimenFiscal.descripcion}`}</ShowInfo>
                                <br/>
                                <h5 style={{margin:'0 0 5px', fontSize: '1.5em'}}>Cliente</h5>
                                <ShowInfo title="Nombre o Razon Social">{comprobante._Receptor._Nombre}</ShowInfo>
                                <ShowInfo title="RFC">{comprobante._Receptor._DatosFiscales._RFC}</ShowInfo>
                                <ShowInfo title="Pais">{`${comprobante._Receptor._DomicilioFiscal._Pais.c_Pais} - ${comprobante._Receptor._DomicilioFiscal._Pais.descripcion}`}</ShowInfo>
                                <ShowInfo title="Estado / Municipio">{`${comprobante._Receptor._DomicilioFiscal._Estado.c_Estado} - ${comprobante._Receptor._DomicilioFiscal._Estado.nombre}`} / {`${comprobante._Receptor._DomicilioFiscal._Municipio}`}</ShowInfo>
                                <ShowInfo title="Colonia">{comprobante._Receptor._DomicilioFiscal._Colonia}</ShowInfo>
                                <ShowInfo title="Localidad">{comprobante._Receptor._DomicilioFiscal._Localidad}</ShowInfo>
                                <ShowInfo title="Calle">{comprobante._Receptor._DomicilioFiscal._Calle}</ShowInfo>
                                <ShowInfo title="No. Externo">{comprobante._Receptor._DomicilioFiscal._Exterior}</ShowInfo>
                                <ShowInfo title="No. Interno">{comprobante._Receptor._DomicilioFiscal._Interior}</ShowInfo>
                                <ShowInfo title="Uso de CFDI">{`${comprobante._Receptor._UsoCFDI.c_UsoCFDI} - ${comprobante._Receptor._UsoCFDI.descripcion}`}</ShowInfo>
                                <ShowInfo title="Contacto">
                                    {`${ comprobante._Receptor._Contactos.map( contacto => contacto._Correo ).join(", ") }`}
                                </ShowInfo>
                            </Loading>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <label>Observaciones:</label>
                            <input 
                                type="text" 
                                name="_Observaciones"
                                value={comprobante._Observaciones}
                                onChange={ this.updateForm.bind(this) } 
                                className="form-control" 
                            />
                            { this.UsaFacturaPorConsumo(empresa)?(
                                <FormGroup>
                                    <Checkbox name="_ComoConsumo"  checked={ form._ComoConsumo } onChange={ this.onCheckPorConsumo.bind(this) } >
                                        Facturar como consumo
                                    </Checkbox> 
                                </FormGroup>
                            ): false }
 
                            <div className="table-responsive">
                                <table className="table table-bordered table-condensed table-striped table-hover" style={{color:"#555"}}>
                                    <thead>
                                        <tr>
                                            {/* <th className="text--center">
                                                <input 
                                                    type="checkbox" 
                                                    name="_AllSelected"  
                                                    checked={ comprobante._AllSelected } 
                                                    onChange={ this.updateAllSelected.bind(this) }    
                                                />
                                            </th> */}
                                            <th>Cantidad</th>
                                            <th className="text--left">Descripcion </th>
                                            <th className="text--right">Importe</th>
                                        </tr>                                   
                                    </thead>
                                    <tbody>
                                        { comprobante._Conceptos.map( ( concepto, index ) => (
                                            <TableItem
                                                concepto={concepto}
                                                key={`concepto-${index}`}
                                                // onCheck={(e) => this.onCheckConcepto( e, index ) }
                                            />
                                        ))}
                                        <tr>
                                            <th colSpan="2" className="text--right">Subtotal</th>
                                            <th className="text--right">{ currency( comprobante._Subtotal ) }</th>
                                        </tr>
                                        <tr>
                                            <th colSpan="2" className="text--right">Impuestos Retenidos</th>
                                            <th className="text--right">{ currency( comprobante._ImpuestosRetenidos) }</th>
                                        </tr>
                                        <tr>
                                            <th colSpan="2" className="text--right">Impuestos Trasladados</th>
                                            <th className="text--right">{ currency( comprobante._ImpuestosTrasladados ) }</th>
                                        </tr>
                                        <tr>
                                            <th colSpan="2" className="text--right">Descuento</th>
                                            <th className="text--right">{ currency( comprobante._Descuento ) }</th>
                                        </tr>
                                        <tr>
                                            <th colSpan="2" className="text--right">Total</th>
                                            <th className="text--right">{ currency( comprobante._Total ) }</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <button className="btn btn-default btn--block mt-10" onClick={ this.onClickAtras.bind(this) } style={{cursor: 'pointer'}}>Anterior</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-sm-offset-6">
                            <ButtonLoading loading={loadingFacturar} className="btn btn--primary btn--block mt-10" type="submit">
                                Facturar
                            </ButtonLoading>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Comprobante;