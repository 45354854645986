import React from 'react';
import { Route } from 'react-router-dom';
import Busqueda from '../Components/Busqueda';
import Receptor from '../Components/Receptor';
import Layout from '../Components/Layout';
import Comprobante from '../Components/Comprobante';
import Visualizador from '../Components/Visualizador';

const withBaseLayout = ( WrapperComponent ) => {
	class Wrapper extends React.Component {
		render(){
			return (
				<Layout { ...this.props } >
					<WrapperComponent { ...this.props} />
				</Layout>
			);
		}
	}
	return Wrapper;
};

export default [
	<Route exact key="busqueda" path="/:empresaName" component={withBaseLayout(Busqueda)} />,
	<Route exact key="receptor" path="/:empresaName/receptor" component={withBaseLayout(Receptor)} />,
	<Route exact key="comprobante" path="/:empresaName/comprobante" component={withBaseLayout(Comprobante)} />,
	<Route exact key="visualizador" path="/:empresaName/visualizador/:comprobanteId" component={withBaseLayout(Visualizador)} />
];
