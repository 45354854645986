import React from 'react';
import { Button, FormGroup, Checkbox } from "react-bootstrap";
import { ToResult, localStorage } from '../../../Utils';
import { empresas, ventas } from '../Urls';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { 
    EstatusVenta, 
    ShowMessage, 
    TipoAsignacionComprobante, 
    FormatoDate,
    MismaEmpresaUrlYGuardada,
    IMG_LOGO,
    schemaEmpresa,
    Pasos
} from '../Models';
import TextField from '../../../Utils/Common/TextField';
import Required from '../../../Utils/Common/Required';
import LinkTerminos from '../../Public/Components/LinkTerminos';

const INIT_FORM = {
    _RFC:'',
    _Correo:'',
    _SucursalID:'',
    _Folio:'',
    _Total: 0.0,
    _Registrarme: false,
    _AceptoTerminos: false
};


class Busqueda extends React.Component  {
    state = {
        empresa: {
            ...schemaEmpresa
        },
        form: {
            ...INIT_FORM,
            _Fecha: moment().format("YYYY-MM-DD")
        }
    };

    TieneEmpresaName() {
        return !!this.props.match.params.empresaName;
    }

    componentDidMount(){
        const empresa = localStorage.get('empresa');
        if( empresa && MismaEmpresaUrlYGuardada(empresa, this.props ) ){
            this.setState({ empresa }, this.precargarInformacion.bind(this) );
        } else if(this.TieneEmpresaName()){
            this.ObtenerEmpresa(this.precargarInformacion.bind(this));
        } else {
            window.location = "#/";
            return;
        }
    }

    precargarInformacion(){
        const informacion = localStorage.get('informacion');
        if(informacion){
            this.setState({
                form:{
                    ...INIT_FORM,
                    ...informacion,
                    _Fecha: moment().format("YYYY-MM-DD"),
                    _SucursalID: this.ObtenerSucursalDefault()
                }
            });
        }
    }

    ObtenerSucursalDefault(){
        const { empresa } = this.state;
        if( empresa._Sucursales.length === 1)
            return empresa._Sucursales[0]._SucursalID;
        return '';
    }

    ObtenerEmpresa( callback = () => {} ){
        ToResult.ToResultPost( empresas.url_obtenerempresaporshortname, {}, (err, response) => {
            if (response.isValid) 
                this.setState({ empresa: response.data }, callback );
            else
               window.location = "#/";
        });
    }

    ObtenerShortNameEmpresa(){
        return this.props.match.params.empresaName;
    }

    onClickSiguiente(e){
        e.preventDefault();
    }

    updateFormCheckboxExtrajero({ target }){
        const { checked } = target;
        this.setState({
            form: {
                ...this.state.form,
                _RFC: checked ? "XEXX010101000":""  
            }
        });
    }

    updateFormNumber(e ){
        const { name, value } = e.target;
        let { form } = this.state;
        if(!isNaN(Number(value))) {
            this.setState({
                form: {
                    ...form,
                    [name]: value.trim()
                }
            });
        }
    }

    updateForm( { target } ){
        const { name, value, checked } = target;
        let { form } = this.state;
        if(target.type === "checkbox"){
            form = {
                ...form,
                [name]: checked 
            };
        } else {
            form = {
                ...form,
                [name]: name === '_RFC'? value.toUpperCase().trim() : value.trim()
            };
        }
        this.setState({
            form
        });
    }

    PasoElTiempoDeTimbrado( venta, empresa ){
        const dateEmision = moment( moment(venta._FechaEmision).format( FormatoDate ) );
        const dateVencimiento = moment( dateEmision.add(empresa._Configuracion._DatosVenta._DiasTramitarFactura, 'days' ).format( FormatoDate ) );
        const dateHoy = moment( moment().format( FormatoDate ) );
        const dias = moment.duration(dateHoy.diff(dateVencimiento)).asDays();
        return dias > 0;
    }

    onSubmitForm(e){
        e.preventDefault();
        const { form } = this.state;
        ToResult.ToResultPost( ventas.url_ventaporfolio, form , (err, response) => {
            if (ShowMessage(response)) {
                const venta = response.data;
                localStorage.set("informacion", {
                    _RFC: form._RFC.trim(),
                    _Correo: form._Correo.trim(),
                    _Registrarme: form._Registrarme,
                    _AceptoTerminos: form._AceptoTerminos
                });
                localStorage.set('venta', response.data );
                localStorage.set('paso_anterior', Pasos.Busqueda );
                if( venta._Estatus === EstatusVenta.Timbrada ){
                    if(venta._TipoAsignacionComprobante === TipoAsignacionComprobante.Propia){
                        NotificationManager.success("Ticket previamente timbrado", "Aviso");
                        window.location = `#/${this.state.empresa._Configuracion._DatosVenta._ShortName}/visualizador/${venta._ComprobanteID}`;
                        return;
                    }
                }
                if(!this.PasoElTiempoDeTimbrado(venta, venta._Empresa)){
                    NotificationManager.success("Ticket Encontrado", "Aviso");
                    window.location = `#/${this.state.empresa._Configuracion._DatosVenta._ShortName}/receptor`;
                } else {
                    NotificationManager.warning("Lo sentimos, a pasado el limite de dias para la realizacion del comprobante", "Advertencia");
                }
            }
        });
    }

    onClickInicio(e){
        e.preventDefault();
        const { form } = this.state;
        localStorage.set("informacion", {
            _RFC: form._RFC.trim(),
            _Correo: form._Correo.trim(),
            _Registrarme: form._Registrarme,
            _AceptoTerminos: form._AceptoTerminos
        });
        localStorage.set('paso_anterior', 2 );
        window.location = `#/`;
    }

    render(){
        const { form, empresa } = this.state;
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 slide--action">
                <form className="mb-0" onSubmit={ this.onSubmitForm.bind(this) }>
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2">
                            <img className="img-responsive" src={ empresa._Logo || IMG_LOGO } alt=""/>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10">
                            <h5>Bienvenido a nuestro portal de Facturación Electrónica</h5>
                            <div className="alert alert-info">
                            <span className="bold">Aquí podrás facturar, sólo debes tener a la mano tu ticket de compra.</span><br/>
                            <span className="bold">Para la emisión de la factura de su No. Referencia se contemplan únicamente {empresa._Configuracion._DatosVenta._DiasTramitarFactura} días a partir de la fecha de emisión del Ticket de Compra.</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <label>Sucursal: <Required/></label>
                            <select 
                                name="_SucursalID" 
                                value={ form._SucursalID } 
                                onChange={ this.updateForm.bind(this) } 
                                className="form-control"
                                required
                            >
                                <option value="" disabled>Seleccione una sucursal</option>
                                { empresa._Sucursales.filter(sucursal => sucursal._SucursalEmisorID && sucursal._Activa ).map(( sucursal, index ) => (
                                    <option value={sucursal._SucursalID} key={`sucursal-${index}`}>{ sucursal._Nombre }</option>
                                )) }
                            </select>
                        </div>
                        <TextField name="_Folio" value={ form._Folio } onChange={ this.updateForm.bind(this) } required>
                            No referencia o ticket
                        </TextField>
                        <TextField type="date" name="_Fecha" value={ form._Fecha } onChange={ this.updateForm.bind(this) } required>
                            Fecha
                        </TextField>
                        <TextField name="_Total" value={ form._Total } onChange={ this.updateFormNumber.bind(this) } required>
                            Monto
                        </TextField>
                    </div>
                    <div className="row">
                        <TextField type="text" name="_RFC" value={ form._RFC } onChange={ this.updateForm.bind(this) } required >
                            Tu RFC
                        </TextField>
                        <TextField type="email" name="_Correo" value={ form._Correo } onChange={ this.updateForm.bind(this) } required>
                            Tu correo electrónico
                        </TextField>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <br/>
                            <FormGroup>
                                <Checkbox name="_SoyExtranjero"  checked={ form._RFC === "XEXX010101000" } onChange={ this.updateFormCheckboxExtrajero.bind(this) } >
                                    Soy Extranjero
                                </Checkbox>
                                <Checkbox 
                                    name="_AceptoTerminos"  
                                    checked={ form._AceptoTerminos } 
                                    onChange={ this.updateForm.bind(this) }
                                >
                                    Acepto los <LinkTerminos/>
                                </Checkbox>
                                {/* 
                                <Checkbox name="_Registrarme"  checked={ form._Registrarme } onChange={ this.updateForm.bind(this) } >
                                    Registrarme para notificaciones
                                </Checkbox>
                                */}
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <button className="btn btn-default btn--block mt-10" onClick={ this.onClickInicio.bind(this) }>Inicio</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-sm-offset-6">
                            <Button className="btn btn--primary btn--block mt-10" type="submit">Siguiente</Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Busqueda;