import React from 'react';
import { ToResult, localStorage } from '../../../Utils';
import { cfdi, empresas } from '../Urls';
import { schemaComprobante, ShowMessage, IMG_LOGO, schemaEmpresa, MismaEmpresaUrlYGuardada, Pasos, ObtenerContactos } from '../Models';
import ButtonLoading from './Comprobante/ButtonLoading';
import { NotificationManager } from 'react-notifications';
const INIT_FORM = {
    _RFC:'',
    _Correo:'',
    _EmpresaID:'',
    _Registrarme: false,
    _AceptoTerminos: false
};

class Visualizador extends React.Component  {
    state = {
        empresas: [],
        empresa:{
            ...schemaEmpresa
        },
        form:{
            ...INIT_FORM
        },
        comprobanteId: this.ObtenerComprobanteId(),
        rutas: {},
        comprobante: {
            ...schemaComprobante
        }
    };

    componentDidMount(){
        const empresa = localStorage.get('empresa');
        if( empresa && MismaEmpresaUrlYGuardada( empresa, this.props )){
            this.setState({ empresa });
        } else if(this.TieneEmpresaName()){
            this.ObtenerEmpresa();
        } else {
            window.location = "#/inicio";
            return;
        }
        this.ObtenerConfiguracionRutas();
        this.ObtenerComprobantePorId();
    }

    ObtenerEmpresa( callback = () => {} ){
        ToResult.ToResultPost( empresas.url_obtenerempresaporshortname, {}, (err, response) => {
            if (response.isValid) 
                this.setState({ empresa: response.data }, callback );
            else
                window.location = "#/";
        });
    }

    TieneEmpresaName() {
        return !!this.props.match.params.empresaName;
    }

    ObtenerShortNameEmpresa(){
        return this.props.match.params.empresaName;
    }

    ObtenerConfiguracionRutas() {
		ToResult.ToResultPost( cfdi.url_configuracion_rutas, { }, ( err, response ) => {
            this.setState({ rutas: response.data });
        });
    }

    ObtenerComprobantePorId() {
		ToResult.ToResultPost( cfdi.url_ver, { _ComprobanteID: this.state.comprobanteId }, ( err, response ) => {
            if(ShowMessage(response)){
                this.setState({ comprobante: response.data });
            }
        });
	}
    
    xml(comprobante){
        window.open(`${this.state.rutas._RutaXml}${comprobante.timbrado.uuid}?Nombre=${comprobante._Serie}-${comprobante._Folio}`);
	}

	// pdf(comprobante){
	// 	window.open(`#/comprobante/${comprobante._ComprobanteID}`);
	// }

	pdf(comprobante){
        window.open(`${this.state.rutas._RutaPdf}${comprobante.timbrado.uuid}?Nombre=${comprobante._Serie}-${comprobante._Folio}`);
	}

	zip(comprobante){
        window.open(`${this.state.rutas._RutaZip}${comprobante.timbrado.uuid}?Nombre=${comprobante._Serie}-${comprobante._Folio}`);
    }
    
    ObtenerComprobanteId(){
        return this.props.match.params.comprobanteId;
    }

    onClickSiguiente(e){
        e.preventDefault();
    }

    updateForm( { target } ){
        const { name, value, checked } = target;
        let { form } = this.state;
        if(target.type === "checkbox"){
            form = {
                ...form,
                [name]: checked 
            };
        } else {
            form = {
                ...form,
                [name]: value
            };
        }
        this.setState({
            form
        });
    }

    onSubmitForm(e){
        e.preventDefault();
        const { form } = this.state;
        const params = {
            _ComprobanteID: this.state.comprobanteId,
            _Contactos: ObtenerContactos( form._Correo )
        }
        if(params._Contactos.length === 0){
            NotificationManager.warning("El campo correo esta vacio","Advertencia");
            return;
        }
        this.setState({ loadingEnviar: true });
        ToResult.ToResultPost( cfdi.url_enviar_correo_a, params, ( err, response ) => {
            ShowMessage(response)
            this.setState({ loadingEnviar: false });
        });
        
       // window.location = "#receptor";
    }

   

    onClickOtroComprobante(e) {
        e.preventDefault();
        localStorage.set('paso_anterior', Pasos.Visualizador );
        window.location = `#/${this.state.empresa._Configuracion._DatosVenta._ShortName}`;
    }

    onClickInicio(e) {
        e.preventDefault();
        localStorage.set('paso_anterior', Pasos.Visualizador );
        window.location = `#/inicio`;
    }

    MostrarMensajeDeFacturaCorrectamente(){
        const paso = localStorage.set('paso_anterior', Pasos.Comprobante );
        return paso === Pasos.Comprobante;
    }
    render(){
        const { form, comprobante, empresa, loadingEnviar } = this.state;
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 slide--action">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2">
                        <img className="img-responsive" src={ empresa._Logo || IMG_LOGO } alt=""/>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <h5>Paso 4: Descargar factura.</h5>
                        { this.MostrarMensajeDeFacturaCorrectamente() ? (
                            <div className="alert alert-success">
                                <span className="bold">Su factura se ha generado correctamente. </span>
                                <span> y fué enviada automáticamente a su correo electrónico</span>
                            </div>
                        ) : (
                            <div className="alert alert-success">
                                <span className="bold">Su factura se ha generado correctamente anteriormente. </span>
                            </div>
                        ) }
                    </div>
                </div>
                <div className="row">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Enviar a otro correo electrónico</h3>
                        </div>
                        <div className="panel-body">
                            <form className="mb-0" onSubmit={ this.onSubmitForm.bind(this) }>
                                <label>Correo electrónico:</label>
                                <input 
                                    type="text" 
                                    name="_Correo" 
                                    value={form._Correo} 
                                    onChange={this.updateForm.bind(this)}
                                    className="form-control"
                                    required
                                />
                                <ButtonLoading loading={loadingEnviar} bsStyle="success" type="submit">
                                    Enviar
                                </ButtonLoading>
                            </form>
                        </div>
                        <div className="panel-heading">
                            <h3 className="panel-title">Descargar archivos</h3>
                        </div>
                        <div className="panel-footer">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <button 
                                        className="btn btn-warning" 
                                        style={{display:'block', width:"100%"}}
                                        onClick={ this.zip.bind(this, comprobante )}
                                    >
                                        Descargar ZIP
                                    </button>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <button 
                                        className="btn btn-info" 
                                        style={{display:'block', width:"100%"}}
                                        onClick={ this.xml.bind(this, comprobante )}
                                    >
                                        Descargar XML
                                    </button>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <button 
                                        className="btn btn-info" 
                                        style={{display:'block', width:"100%"}}
                                        onClick={ this.pdf.bind(this, comprobante )}
                                    >
                                        Descargar PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <button className="btn btn--primary btn--block mt-10" onClick={ this.onClickOtroComprobante.bind(this) }>Hacer otra factura</button>
                    </div>
                    {/*<div className="col-lg-3 col-md-3 col-sm-3 col-lg-offset-5">
                        <a className="btn btn-default btn--block mt-10" onClick={ this.onClickInicio.bind(this) }>Inicio</a>
                    </div>*/}
                </div>
            </div>
        );
    }
}

export default Visualizador;