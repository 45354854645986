import http from './http';

class ToResult{
    static ejecutarCallBack = (callback, err, response) => {
        if (typeof callback !== 'function') return false;

        if(err){
            callback(err);
        }else{
            callback(null, response);
        }
    };
    static GetResult = (url, callback) =>{
        http.get(url,(err,response)=>{
            ToResult.ejecutarCallBack(callback,err, response);
        });
    };

    static PostResult = (url, data, callback) =>{
        http.post(url,data,(err, response)=>{
            ToResult.ejecutarCallBack(callback, err, response);
        })
    }
}

export default{
    ToResultGet (url, callback){
        ToResult.GetResult(url,callback);
    },
    ToResultPost (url,data,callback){
        ToResult.PostResult(url,data,callback);
    },
    ValidaUso (r,callback){
        ToResult.ValidaUsoPost(r,callback);
    },
    ValidaDemo(){
        ToResult.ValidaDemo();
    }
};
