import BaseLayout from './Components/Layouts/BaseLayout';
import { HashRouter }from 'react-router-dom';
import React from 'react';
import routes from './Routes';

class Base extends React.Component {
	render() {
		return (
			<HashRouter>
				<BaseLayout>
					{ routes }
				</BaseLayout>
			</HashRouter>
		);
	}
}

export default Base;
