import React from 'react';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

class BaseLayout extends React.Component {
	render() {
		return [
			this.props.children,
			<NotificationContainer key="notifications"/>
		];
	}
}

export default BaseLayout;