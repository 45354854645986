import React from 'react';
import { Button } from "react-bootstrap";
import { ToResult, localStorage } from '../../../Utils';
import { empresas, sat, receptores } from '../Urls';
import TextField from '../../../Utils/Common/TextField';
import { schemaEmpresa, MismaEmpresaUrlYGuardada, ShowMessage, Pasos, ObtenerTipoPersona, IMG_LOGO } from '../Models';
const INIT_FORM = {
    _RFC:'',
    _Correo:'',
    _EmpresaID:'',
    _Pais:'MEX',
    _Estado:'',
    _UsoCFDI:'',
    _Registrarme: false,
    _AceptoTerminos: false
};

class Busqueda extends React.Component  {
    state = {
        empresas: [],
        usoscfdi: [],
        paises: [],
        estados: [],
        codigospostales: [],
        receptor:{
              
        },
        empresa: {
            ...schemaEmpresa 
        },
        form: {
            ...INIT_FORM
        }
    };
    
    componentDidMount(){
        const empresa = localStorage.get('empresa');
        if( empresa && MismaEmpresaUrlYGuardada( empresa, this.props )){
            this.setState({ empresa }, this.ObtenerReceptorDelEmisor.bind(this));
        } else if(this.TieneEmpresaName()){
            this.ObtenerEmpresa(this.ObtenerReceptorDelEmisor.bind(this));
        } else {
            window.location = "#/";
            return;
        }
        this.ObtenerDatosReceptor();
        this.precargarInformacion();
    }

    precargarInformacion(){
        const informacion = localStorage.get('informacion');
        if(informacion){
            this.setState({
                form: {
                    ...INIT_FORM,
                    ...informacion,
                }
            });
        }
    }

    TieneEmpresaName() {
        return !!this.props.match.params.empresaName;
    }

    ObtenerShortNameEmpresa(){
        return this.props.match.params.empresaName;
    }

    ObtenerReceptorDelEmisor(){
        const { empresa, form } = this.state;
        const informacion = localStorage.get('informacion');
        ToResult.ToResultPost( receptores.url_obtenerreceptordelemisor, { 
            _EmisorID: empresa._EmisorID,
            _RFC: informacion._RFC
         }, (err, response) => {
             if(response.data){
                 const { _DomicilioFiscal, _Nombre } = response.data;
                 this.setState({
                    receptor: response.data,
                    form:{
                        ...form,
                        ..._DomicilioFiscal,
                        _CP: _DomicilioFiscal._CP.c_CodigoPostal,
                        _Pais: _DomicilioFiscal._Pais.c_Pais || _DomicilioFiscal._Estado.c_Pais,
                        _Estado: _DomicilioFiscal._Estado.c_Estado,
                        _Nombre
                    }
                });
             }
        });
    }
    
    ObtenerEmpresa( callback = () => {} ){
        ToResult.ToResultPost( empresas.url_obtenerempresaporshortname, {}, (err, response) => {
            if (response.isValid) 
                this.setState({ empresa: response.data }, callback );
            else
                window.location = "#/";
        });
    }

    ObtenerDatosReceptor(){
        ToResult.ToResultPost( sat.url_pais, {}, (err, response) => {
            if (response.isValid) this.setState({ paises: response.data });
        });
        ToResult.ToResultPost( sat.url_estados, {}, (err, response) => {
            if (response.isValid) this.setState({ estados: response.data });
        });
        ToResult.ToResultPost( sat.url_usocfdi, {}, (err, response) => {
            if (response.isValid) this.setState({ usoscfdi: response.data });
        });
    }

    onClickSiguiente(e){
        e.preventDefault();
    }

    updateForm( { target } ){
        const { name, value, checked } = target;
        let { form } = this.state;
        if(target.type === "checkbox"){
            form = {
                ...form,
                [name]: checked 
            };
        } else {
            form = {
                ...form,
                [name]: value
            };
        }
        this.setState({
            form,
            modifico: true
        });
    }

    onSubmitForm(e){
        e.preventDefault();
        const { 
            empresa, 
            form, 
            receptor,
            estados,
            paises,
            usoscfdi
        } = this.state;
        const params = {
            _GrupoID: empresa._GrupoID,
            _Receptor: {
                ...receptor,
                _Nombre: form._Nombre,
                _DatosFiscales: {
                    ...receptor._DatosFiscales,
                    _RFC: form._RFC.trim(),
                    _Tipo: ObtenerTipoPersona( form._RFC )
                },
                _DomicilioFiscal: {
                    ...receptor._DomicilioFiscal,
                    _Calle: form._Calle,
                    _Colonia: form._Colonia,
                    _Exterior: form._Exterior,
                    _Interior: form._Interior,
                    _Localidad: form._Localidad,
                    _Municipio: form._Municipio,
                    _Estado: estados.filter( estado => estado.c_Estado === form._Estado )[0] || {},
                    _Pais: paises.filter( estado => estado.c_Pais === form._Pais )[0] || {},
                    _CP:{ c_CodigoPostal: form._CP }
                },
                _Contactos: [{
                        _Nombre : "Contacto Personal",
                        _Correo : form._Correo,
                        _Telefono : "",
                        _Celular : "",
                        _Enviar : true,
                        _Guardar : true
                    }
                ]
            }
        };
        ToResult.ToResultPost( receptores.url_crear, params , (err, response) => {
            if(response.isValid) {
                localStorage.set('paso_anterior', Pasos.Receptor );
                localStorage.set('receptor',response.data);    
                localStorage.set('uso_cfdi',usoscfdi.filter( usocfdi => usocfdi.c_UsoCFDI === form._UsoCFDI )[0] || {});    
                window.location = `#/${this.state.empresa._Configuracion._DatosVenta._ShortName}/comprobante`;
            } else{
                ShowMessage(response);
            }

        });
    }

    onClickAtras(e){
        e.preventDefault();
        window.location = `#/${this.state.empresa._Configuracion._DatosVenta._ShortName}`;
    }

    render(){
        const { form, empresa, paises, estados, usoscfdi } = this.state;
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 slide--action">
                <form className="mb-0" onSubmit={ this.onSubmitForm.bind(this) }>
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2">
                        <img className="img-responsive" src={ empresa._Logo || IMG_LOGO } alt=""/>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <h5>Paso 2: Aquí debes colocar tus datos fiscales.</h5>
                        <div className="alert alert-info">
                            <span className="bold">Proporciona tus datos fiscales.</span><br/>
                            <span className="bold">Para la emisión de la factura de su No. Referencia se contemplan únicamente {empresa._Configuracion._DatosVenta._DiasTramitarFactura} días a partir de la fecha de emisión del Ticket de Compra.</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3">
                        <label>Pais:</label>
                        <select name="_Pais" value={ form._Pais } onChange={ this.updateForm.bind(this) }  className="form-control">
                            <option value="" disabled>Seleccione un país</option>
                            { paises.map(( pais, index ) => (
                                <option value={pais.c_Pais} key={`pais-${index}`}>{ pais.descripcion }</option>
                            )) }                        
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                        <label>Estado:</label>
                        <select name="_Estado" value={ form._Estado } onChange={ this.updateForm.bind(this) }  className="form-control">
                            <option value="" disabled>Seleccione una estado</option>
                            { estados
                                .filter( ( estado ) => estado.c_Pais === form._Pais )
                                .map(( estado, index ) => (
                                <option value={estado.c_Estado} key={`estado-${index}`}>{ estado.nombre }</option>
                            )) }
                        </select>
                    </div>
                    <TextField name="_Municipio" value={ form._Municipio } onChange={ this.updateForm.bind(this) }>
                        Delegación / Municipio
                    </TextField>
                    <TextField name="_Colonia" value={ form._Colonia } onChange={ this.updateForm.bind(this) }>
                        Colonia
                    </TextField>
                </div>
                <div className="row">
                    <TextField name="_Calle" value={ form._Calle } onChange={ this.updateForm.bind(this) }>
                        Calle
                    </TextField>
                    <TextField name="_Exterior" value={ form._Exterior } onChange={ this.updateForm.bind(this) }>
                        No exterior
                    </TextField>
                    <TextField name="_Interior" value={ form._Interior } onChange={ this.updateForm.bind(this) }>
                        No interior
                    </TextField>
                    <TextField name="_CP" value={ form._CP } onChange={ this.updateForm.bind(this) }>
                        Codigo postal
                    </TextField>
                </div>
                <div className="row">
                    <TextField name="_Localidad" value={ form._Localidad } onChange={ this.updateForm.bind(this) }>
                        Localidad
                    </TextField>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                        <label>Uso de CFDI:</label>
                        <select name="_UsoCFDI" value={ form._UsoCFDI } onChange={ this.updateForm.bind(this) }  className="form-control" required>
                        <option value="" disabled>Seleccione un uso cfdi</option>
                            { usoscfdi.map(( usocfdi, index ) => (
                                <option value={usocfdi.c_UsoCFDI} key={`usocfdi-${index}`}>{ usocfdi.c_UsoCFDI } - { usocfdi.descripcion }</option>
                            )) }  
                        </select>
                    </div>
                    <TextField name="_Nombre" cols="6" value={ form._Nombre } onChange={ this.updateForm.bind(this) }>
                        Razon social
                    </TextField>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3">
                           <button className="btn btn-default btn--block mt-10" onClick={ this.onClickAtras.bind(this) }>Anterior</button>
                    </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-sm-offset-6">
                            <Button className="btn btn--primary btn--block mt-10" type="submit">Siguiente</Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Busqueda;