import React from  'react';
import { Button } from "react-bootstrap";

export default ( { loading, onChange, ...props } ) => {
    return loading? (
        <Button
            { ...props }
            disabled
        >
            <i className="fa fa-spinner fa-spin"></i> &nbsp;
            Cargando...
        </Button>
    ): (
        <Button
            { ...props }
            onChange={ onChange }
        >
            { props.children }
        </Button>
    );
}


