import { reactLocalStorage } from 'reactjs-localstorage';

const HOURS = 24;
const GetHoursInMiliSeconds = ( hours ) =>  hours * 60 * 60 * 1000;

export default class AmplifyHelperClass {
	
	get(key) {
		if( this.expiredObject(key) ) return undefined;
		return reactLocalStorage.getObject(key);
	}
	
	set(key, data, expires) {
		reactLocalStorage.setObject(key, data);
		this.setExpiredObject(key);
	}

	setExpiredObject(key = '') {
		const now = new Date().getTime();
		const setupTime = reactLocalStorage.getObject(`setupTime_${key}`);
		if (setupTime == null) {
			reactLocalStorage.setObject(`setupTime_${key}`, now);
		} else {
			if( ( now - setupTime ) > GetHoursInMiliSeconds(HOURS)) {
				reactLocalStorage.setObject(`setupTime_${key}`, now);
			}
		}
	}

	expiredObject(key=''){
		const now = new Date().getTime();
		const setupTime = reactLocalStorage.getObject(`setupTime_${key}`);
		if (setupTime == null) {
			return true;
		} else {
			if( ( now - setupTime ) > GetHoursInMiliSeconds(24)) {
				return false;
			}
		}
	}

}

