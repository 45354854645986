import { NotificationManager } from 'react-notifications';

export const schemaEmisor = {
    _Sucursal: {},
    _RegimenFiscal: {},
    _DatosFiscales:{},
    _DomicilioFiscal:{},
    _DomicilioFisico:{}
}

export const schemaProducto = {
	_NoIdentificacion:'',
	_Descripcion:'',
	_Unidad:{},
	_Impuestos:[],
	_ValoresUnitarios:[],
	_Familias:[],
	_Moneda:{}
}


export const schemaDomicilioReceptor = {
    _Pais: {
        c_Pais: "MEX"
    },
    _Estado: {},
    _CP: {}
}

export const schemaReceptor = {
    _DomicilioFiscal: {
        ...schemaDomicilioReceptor
    },
    _DatosFiscales: {},
    _Contactos: [],
    _Grupos: [],
}


export const INIT_MONEDA = {
    c_Moneda:'MXN' 
} 

export const INIT_FORMAPAGO = {
    c_FormaPago:'01'
} 

export const INIT_METODOPAGO = {
    c_MetodoPago:'PUE'
} 

export const INIT_TIPOCOMPROBANTE = {
    _TipoComprobanteID:'FA'
}

export const INIT_USOCFDI = {
    c_UsoCFDI: ''
}

export const schemaComprobante = {
    _Serie:'',
    _Folio:'',
    _CfdiRelacionados: {
        _TipoRelacion:{},
        _Comprobantes:[]
    },
    _Emisor: {
        ...schemaEmisor
    },
    _Moneda: { 
        ...INIT_MONEDA
    },
    _FormaPago: {
        ...INIT_FORMAPAGO
    },
    _MetodoPago: {
        ...INIT_METODOPAGO
    },
    _TipoComprobante: {
        ...INIT_TIPOCOMPROBANTE
    },
    _Receptor: {
        ...schemaReceptor,    
        _UsoCFDI: {
            ...INIT_USOCFDI
        },
    },
    _Conceptos: [],
    _Complementos: [],
    _NotasRelacionadas: [],
    _Adenda: { 
        _Renglones: []
    }
}

export const RegexRFC = new RegExp(/^[A-Z&amp;Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/);

export const Numero = (Num) => Number( Num || 0 )


export const CalcImporteConcepto = ( concepto ) => 
            Numero(concepto._Cantidad) * Numero(concepto._Producto._ValorUnitario);

export const CalcSubTotalConcepto = ( concepto ) => 
            CalcImporteConcepto(concepto) - CalcDescuentoConcepto(concepto);

// Duda sobre si es asi el descuento
export const CalcDescuentoConcepto = ( concepto ) => 
             ( Numero(concepto._Descuento)  / 100 ) * CalcImporteConcepto( concepto );

export const ImpuestoFactorExento = ( impuesto ) => 
            impuesto._TipoFactor.c_TipoFactor === 'Exento'; 

export const ImpuestoFactorTasa = ( impuesto ) => 
            impuesto._TipoFactor.c_TipoFactor === 'Tasa'; 

export const EsImpuestoTraslado = ( impuesto ) => 
            impuesto._TipoImpuesto._Codigo === 'T';
export const EsImpuestoRetencion = ( impuesto ) => 
            impuesto._TipoImpuesto._Codigo === 'R';

export const CalcUnImpuesto = ( impuesto, index, base ) => {
	if(ImpuestoFactorExento(impuesto)) return 0;
	const _Total = ImpuestoFactorTasa(impuesto) ? ( Numero(impuesto._TasaOCuota.rangoFinal) * base ): Numero(impuesto._TasaOCuota.rangoFinal);
	// return EsImpuestoTraslado(impuesto)? _Total : _Total * -1;
	return _Total;
}

export const CalcImpuestosConcepto = ( concepto ) => {
	const base = CalcSubTotalConcepto(concepto);
	return concepto._Producto._Impuestos
			.map( (impuesto,index) => {
				const _Total = CalcUnImpuesto( impuesto, index, base );
				return EsImpuestoTraslado(impuesto)? _Total : _Total * -1;
			})
			.reduce(( p, c ) => p + c, 0);
}

export const CalcImpuestosRetenidosConcepto = ( concepto ) => {
	const base = CalcSubTotalConcepto(concepto);
	return concepto._Producto._Impuestos
			.filter(EsImpuestoRetencion)
			.map(( impuesto, index ) => CalcUnImpuesto( impuesto, index, base )).reduce((p, c) => p + c, 0);
}

export const CalcImpuestosTrasladadosConcepto = ( concepto ) => {
	const base = CalcSubTotalConcepto(concepto);
	return concepto._Producto._Impuestos
			.filter(EsImpuestoTraslado)
			.map((impuesto,index) => CalcUnImpuesto( impuesto, index, base )).reduce((p, c) => p + c, 0);
}

export const CalcTotalConcepto = ( concepto ) =>  CalcSubTotalConcepto(concepto) + CalcImpuestosConcepto(concepto);

export const CalcSubTotal = ( _Conceptos ) => _Conceptos.map(CalcImporteConcepto).reduce((p, c) => p + c, 0);

export const CalcImpuestos = ( _Conceptos ) => _Conceptos.map(CalcImpuestosConcepto).reduce((p, c) => p + c, 0);

export const CalcImpuestosRetenciones = ( _Conceptos ) => _Conceptos.map(CalcImpuestosRetenidosConcepto).reduce((p, c) => p + c, 0);

export const CalcImpuestosTrasladados = ( _Conceptos ) => _Conceptos.map(CalcImpuestosTrasladadosConcepto).reduce((p, c) => p + c, 0);

export const CalcDescuentos = ( _Conceptos ) => _Conceptos.map(CalcDescuentoConcepto).reduce((p, c) => p + c, 0);

export const CalcTotal = ( _Conceptos ) => _Conceptos.map(CalcTotalConcepto).reduce((p, c) => p + c, 0);

export const CalcTotales = ( { _Conceptos } ) => {
	return {
		_Subtotal: CalcSubTotal(_Conceptos),
		_Impuesto: CalcImpuestos(_Conceptos),
		_ImpuestosRetenidos : CalcImpuestosRetenciones(_Conceptos),
		_ImpuestosTrasladados : CalcImpuestosTrasladados(_Conceptos),
		_Descuento: CalcDescuentos(_Conceptos),
		_Total: CalcTotal(_Conceptos)
	};
}


export const currency = ( number = 0, decimal = 2 ) => `$ ${parseFloat(number).toFixed(decimal).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;


export const DireccionEmisor = ( { _DomicilioFiscal } ) => {
    return `${_DomicilioFiscal._Calle || ''} # ${_DomicilioFiscal._Exterior || ''} ${_DomicilioFiscal._Interior || ''}, ${_DomicilioFiscal._Colonia || ''}, ${_DomicilioFiscal._CP || ''} ${_DomicilioFiscal._Municipio || ''}, ${_DomicilioFiscal._Estado || ''} ${_DomicilioFiscal._Pais  || ''}`;
}; 

export const EmisorComprobante = ( emisor, _SucursalEmisorID , regimenes ) => {
    const sucursal = emisor._Sucursales.filter((_Sucursal) => _Sucursal._SucursalID === _SucursalEmisorID)[0];
    let regimen = {};
    if (emisor._DatosFiscales._Regimenes.length >= 1) {
        regimen = regimenes.filter((r) => r.c_RegimenFiscal === emisor._DatosFiscales._Regimenes[0])[0];
    }
    return { 
        ...emisor, 
        _Sucursal: { 
            ...sucursal 
        }, 
        _RegimenFiscal: { ...regimen}  
    };
}

export const OcupaTipoCambio = (_Moneda) => {
    return _Moneda.c_Moneda !== 'MXN' && _Moneda.c_Moneda !== 'XXX';
}

export const FormatoDate = 'YYYY-MM-DD';
export const FormatoDateTime = 'YYYY-MM-DD HH:mm:ss';
export const FormatoDateTimeComprobante = 'YYYY-MM-DD HH:mm:ss';

export const IMG_LOGO = "assets/images/logo/logo.png";


export const ArmarClienteConReceptor = ( receptor, vendedor ) => {
    return {
        _NoCliente: receptor._NoCliente,
        _Nombre : receptor._Nombre,
        _NombreComercial : receptor._NombreComercial,
        _RFC : receptor._DatosFiscales._RFC,
        _Domicilio: {
            ...receptor._DomicilioFiscal
        },
        _Contactos: receptor._Contactos.map( ( contacto ) => { 
            return {
                _Nombre : contacto._Nombre,
                _Correo : contacto._Correo,
                _Telefono : contacto._Telefono,
                _Celular : contacto._Celular,
                _Enviar : true,
                _Guardar : true
            }; 
        }),
        _Grupos: receptor._Grupos,
        _Fletes: [],
        _Transportes: [],
        _UsaPaqueteria: false,
        _ExcluyeComision: false,
        _VendedorPrincipalID: vendedor._VendedorID,
        _Activo : true,
    }
}

export const GetTypeMessage = (type) => {
    switch (type) {
        case 4:
            return { type: 'success', title: 'Aviso'};
        case 2:
        case 6:
        case 7:
            return {type: 'info', title: 'Aviso'};
        case 0:
        case 3:
        case 5:
            return {type: 'warning', title: 'Advertencia'};
        default:
            return {type: 'error', title: 'Error'};
      }
}
export const ShowMessage = ( result ) => {
	if(result.hasMessage){
        const msj = GetTypeMessage(result.type);
		NotificationManager[msj.type](result.message, msj.title);
	}
	return result.isValid;
};

export const EstatusVenta = {
	Generada: 0,
	Cancelada: 1,
	Timbrada: 2,
    PorAprobar: 3,
    PendienteCancelacion: 4
};

export const TipoAsignacionComprobante = {
	NoAsinado: 0,
	Propia: 1,
	General: 2
};

export const schemaEmpresa = {
    _Configuracion:{
        _DatosVenta:{
            
        }
    },
    _Sucursales: []
}


export const MismaEmpresaUrlYGuardada = ( empresa, { match } ) => {
    return empresa && 
        empresa._Configuracion  && 
        empresa._Configuracion._DatosVenta && 
        empresa._Configuracion._DatosVenta._ShortName === match.params.empresaName; 
}

export const OcupaNotaDeCredito = ( venta ) => 
            venta._Estatus === EstatusVenta.Timbrada && 
            venta._TipoAsignacionComprobante === TipoAsignacionComprobante.General;

export const ObtenerTipoComprobanteNotaDeCredito = ( tiposcomprobantes = [] ) => 
    tiposcomprobantes.filter(x => x._TipoComprobanteID === "NC" )[0];


export const ObtenerTipoRelacionNotadeCredito = ( tiposrelaciones = [] ) => 
    tiposrelaciones.filter(x => x.c_TipoRelacion === "01" )[0];


export const TieneMismosConcepto = ( _Producto1, _Producto2 ) => {
    if(_Producto1._TieneImpuestos !== _Producto2._TieneImpuestos || _Producto1._Impuestos.length !== _Producto2._Impuestos.length )
        return false;
    let mismosImpuestos = true;
    _Producto1._Impuestos.forEach((imp1)=>{
        let tieneElImpuesto = false;
        _Producto2._Impuestos.forEach((imp2)=>{
                if( imp1._Impuesto.c_Impuesto === imp2._Impuesto.c_Impuesto  && 
                    imp1._TipoFactor.c_TipoFactor === imp2._TipoFactor.c_TipoFactor &&
                    imp1._TipoImpuesto._Codigo === imp2._TipoImpuesto._Codigo  &&
                    imp1._TasaOCuota.rangoFinal === imp2._TasaOCuota.rangoFinal 
                ){
                tieneElImpuesto = true;
                }
        });
        if(!tieneElImpuesto){
            mismosImpuestos = false;
        }
    });
    return mismosImpuestos;
}


export const ObtenerUsuarioYGrupo = ( venta ) => {
    return {
        _GrupoID : venta._GrupoID,
        _UserID : venta._UserID,
        _UserName : venta._UserName
    }
}


export const ObtenerUsoGenerico = ( usoscfdi ) => usoscfdi.filter((u) => u.c_UsoCFDI === 'G01')[0];


export const ObtenerTipoPersona = ( _RFC ) => {
    if (_RFC === 'XEXX010101000') {
        return 2;
    }
    if (_RFC.length === 13) {
        return 0;
    }
    return 1;
}


export const ReceptorPerteneceAlGrupo = ( _Grupos, _GrupoID ) => {
    let pertenece =  false;
    if (!_Grupos)
        return false;
    _Grupos.forEach( ( grupo ) => {
        if (grupo === _GrupoID) {
            pertenece = true;
        }
    });
    return pertenece;
}


export const ReceptorComprobante = (receptor, usoscfdi ) => {
    const uso = ObtenerUsoGenerico(usoscfdi);
    receptor._UsoCFDI = uso;
    return receptor;
}


export const ObtenerConceptosSeleccionados = ({ _Conceptos }) => {
    return {
        _Conceptos: _Conceptos.filter(( _Concepto )=> _Concepto.isSelected )
    };
}


export const Pasos = {
    Inicio: 1,
    Busqueda: 2,
    Receptor: 3,
    Comprobante: 4,
    Visualizador: 5
}


export const ObtenerContactos = ( _ContactosString ) => {
    if(!_ContactosString)
        return []; 
    const _Contactos = [];
    const _ContactosArray = _ContactosString.split(",");
    for (let i = 0; i < _ContactosArray.length; i++) {
        const _Correo = _ContactosArray[i];
        _Contactos.push({
            _Nombre : `Contacto ${(i + 1)}`,
            _Correo : _Correo.trim(),
            _Telefono : "",
            _Celular : "",
            _Enviar : true,
            _Guardar : true
        });
    }
    return _Contactos;
}