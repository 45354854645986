import React from 'react';
import Required from './Required';

export default ({ children, cols = 3, text= '', onChange = () => {}, name, value, type, required = false }) => {
    return (
        <div className={`col-lg-${cols} col-md-${cols} col-sm-${cols}`}>
            <label>{ children || text}: { required ? <Required/>  : '' }</label>
            <input 
                type={ type || "text"} 
                name={ name } 
                value={ value } 
                onChange={ onChange }  
                className="form-control"
                required={required?"required":"" }
            />
        </div>
    );
}