import React from 'react';
import $ from 'jquery';
import Formulario from './Formulario';
import AutoAffix from 'react-overlays/lib/AutoAffix';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
 
class Inicio extends React.Component  {
    componentDidMount(){
    }
    render(){
        $(document).ready(()=>{
            var $bgSection = $(".bg-section");
            if($bgSection){
                $bgSection.each(function() {
                    var bgSrc = $(this).children("img").attr("src");
                    var bgUrl = 'url(' + bgSrc + ')';
                    $(this).parent().css("backgroundImage", bgUrl);
                    $(this).parent().addClass("bg-section");
                    $(this).remove();
                });
            }
        });
        return (
            <div key="container-1">
                <AutoAffix 
                    container={this} 
                    topClassName="affix-top"
                    affixClassName="affix">
                    <nav id="primary-menu" className="header header-1 header-transparent header-fixed navbar navbar-fixed-top">
                        <div className="container">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a className="logo" href="#/">
                                    <img className="logo-dark" src="assets/images/logo/logo-dark.png" alt="Has tu factura"/>
                                </a>
                            </div>
                            <div className="collapse navbar-collapse pull-right" id="navbar-collapse-1">
                                <ul className="nav navbar-nav nav-pos-right navbar-left nav-split">
                                    <li className="active">
                                        <AnchorLink   href="#slider">Inicio</AnchorLink>
                                    </li>
                                    <li>
                                        <AnchorLink  href="#feature1">¿Qué hacemos?</AnchorLink>
                                    </li>
                                    <li>
                                        <AnchorLink  href="#reviews">Testimonios</AnchorLink>
                                    </li>
                                    <li>
                                        <AnchorLink  href="#clients">Clientes</AnchorLink>
                                    </li>
                                    <li>
                                        <AnchorLink   href="#cta">Registrate</AnchorLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </AutoAffix>
                <section id="slider" className="section slider slider-form">
                    <div className="slide--item bg-overlay bg-overlay-dark">
                        <div className="bg-section">
                            <img src="assets/images/background/bg-5.jpg" alt="bg"/>
                        </div>
                        <div className="container">
                            <div className="row row-content">
                                <div className="col-xs-12 col-sm-6 col-md-5 pt-30">
                                    <div className="slide--holder">
                                        <img className="img-responsive" src="assets/images/logo/logo.png" alt="Has tu factura" />
                                    </div>
                                    <div className="slide--headline">Generador de Factura Electrónica</div>
                                    <div className="slide--bio">Aquí podrás facturar tu consumo o ticket en 4 sencillos pasos, sólo debes tener a la mano tu ticket de compra.</div>
                                </div>
                                <Formulario nombreEmpresa={this.props.match.params.empresaName} />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="feature1" className="section feature feature-left bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-4 col-md-4">
                                <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                                    <div className="feature--icon">
                                        <i className="lnr lnr-chart-bars"></i>
                                    </div>
                                    <div className="feature--content">
                                        <h3>Mantenemos tus comprobantes</h3>
                                        <p>Con tu correo electronico y RFC podras consultar los comprobantes que generaste con nosotros</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4">
                                <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                                    <div className="feature--icon">
                                        <i className="lnr lnr-users"></i>
                                    </div>
                                    <div className="feature--content">
                                        <h3>Servio personalizado</h3>
                                        <p>Tu información para nostros es importante y estara protegida y con nosotros el tiempo que decidas.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4">
                                <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                                    <div className="feature--icon">
                                        <i className="lnr lnr-gift"></i>
                                    </div>
                                    <div className="feature--content">
                                        <h3>Ahorra tiempo</h3>
                                        <p>Nuestro servicio es la manera mas facil de generar tu factura, pues te identificaremos siempre que llegues a hacer tu factura con nosotros.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pt-0 pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <hr className="feature-divider"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="reviews" className="section reviews pb-70 bg-white">
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
                                <div className="heading heading-1 mb-80 text--center wow fadeInUp" data-wow-duration="1s">
                                    <h2 className="heading--title">¿Qué dicen de nosotros?</h2>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6">
                                <div className="testimonial-panel wow fadeInUp "  data-wow-duration="1s">
                                    <div className="testimonial--img">
                                        <img src="assets/images/testimonials/1.png" alt="author"/>
                                    </div>
                                    <div className="testimonial--body">
                                        <div className="testimonial--author">
                                            <h5>Mark Smith</h5>
                                        </div>
                                        <div className="testimonial--job">
                                            Envato Inc.
                                        </div>
                                        <p>"This should be used to tell a story and include any testimonials you might have about your product or service for your clients."</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6">
                                <div className="testimonial-panel wow fadeInUp "  data-wow-duration="1s">
                                    <div className="testimonial--img">
                                        <img src="assets/images/testimonials/2.png" alt="author"/>
                                    </div>
                                    <div className="testimonial--body">
                                        <div className="testimonial--author">
                                            <h5>Jessy Arthur</h5>
                                        </div>
                                        <div className="testimonial--job">
                                            Apple Inc.
                                        </div>
                                        <p>"This should be used to tell a story and include any testimonials you might have about your product or service for your clients."</p>
                                    </div>
                                </div>
                            </div> 
                        </div>*/}
                    </div>
                </section>
                <section id="clients" className="section clients clients-carousel">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <OwlCarousel
                                    className="carousel carousel-dots"
                                    loop
                                    margin={10}
                                    nav
                                >
                                    <div className="client">
                                        <img className="center-block " height="50px" src="assets/images/clients/logoclimas.png" alt="client"/>
                                    </div>
                                    <div className="client">
                                        <img className="center-block " height="50px" src="assets/images/clients/logocooltemp.png" alt="client"/>
                                    </div>
                                    <div className="client">
                                        <img className="center-block " height="50px" src="assets/images/clients/logotaller.png" alt="client"/>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="cta" className="section cta text-center bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                <h3 className="heading--title">Registrate con nosotros</h3>
                                <p className="heading--desc">Recibe alertas y notificaciones del estado de las facturas que generaste con nostros</p>
                                {/* <a className="btn btn--primary" href="#">Quiero registrarme...</a> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Inicio;