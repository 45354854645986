import { BASE_URL } from '../../Configuracion';

let fetchservices = [];
const addurl = function(url){fetchservices.push(url);};
const removeurl =function (url){fetchservices.pop(url);};
const findurl = function (url){
	for( let x = 0; x < fetchservices.length; x++){ if( fetchservices[x] === url ) return false; }
	addurl(url);return true;
};

const httpService = {
	get(params) {
		let routeURL;
		let serverURL;
		const settings = {
			method: 'GET',
			headers: {
				 token: undefined //amplify.store('token')
			}
		};

		if (typeof params === 'object') {
			routeURL = params.url;
			serverURL = params.server;
		} else {
			routeURL = params;
			serverURL = BASE_URL;
		}
		if(findurl(routeURL)){
			return fetch(serverURL + routeURL, settings).then(response => {
				if (response.status !== 200) {
					removeurl(routeURL);
					throw new Error(response.statusText);
				}
				removeurl(routeURL);
				return response.json();
			});
		}
		return { isvalid:false };

	},

	post(params, body) {
		let routeURL;
		let serverURL;
		const settings = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				token: undefined// amplify.store('token')
			},
			body: JSON.stringify(body)
		};
		if (typeof params === 'object') {
			routeURL = params.url;
			serverURL = params.server;
			settings.mode = 'no-cors';
		} else {
			routeURL = params;
			serverURL = BASE_URL;
		}
		if(findurl(routeURL)){
			return fetch(serverURL + routeURL, settings).then((response) => {
				if (response.status !== 200) {
					removeurl(routeURL);
					throw new Error(response.statusText);
				}
				removeurl(routeURL);
				return response.json();
			});
		}
		return { isvalid: false };
	}

};

// exportar servicio
export default httpService;
