import React from  'react';

export default ({loading, children }) => {
    return loading? (
        <div className="text-center">
            <h3>
                <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </h3>
            <span style={{fontSize:'2em'}}>Loading...</span>
        </div>
    ): children;
}