import httpService from './httpService';

export default {
    get(request, callback) {
		const response = httpService.get(request);
		if (response.then) {
			response.then((response) => {
                callback(null, response);
            });
            // .catch((err) => {
            //     callback(`${err}`);
            // });
		}

    },
    post(request, body, callback) {
		const response = httpService.post(request, body);
		if (response.then) {
			response.then((response) => {
				callback(null, response);
			});
		}

    }
};
