import moment from 'moment';

moment.locale('es');
//moment.updateLocale('es', null);

const formatter = {
	date(date, formatString) {
		if (!date) return  moment().format(formatString);
		if (!formatString) formatString = 'D MMMM YYYY';
		return moment(date).format(formatString);
	},
	calendar(date){
		if (!date) return moment().calendar();
		return moment(date).calendar();
	},
	calendarDay(date){
		if (!date) return moment().calendar();
		return moment(date).format('dddd');
	},
	longDate(date){
        moment.locale('es');
		return moment(date).format('D [de] MMMM [del] YYYY');
	},
	currency(number = 0,decimal = 2) {
		const formattedNumber = `$ ${parseFloat(number).toFixed(decimal).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
		const arrayNumber = formattedNumber.split('.');
		return arrayNumber[1] === '00' ? arrayNumber[0] : formattedNumber;
	},
	format(){
		var str = arguments[0];
		for (var i = 1; i < arguments.length; i++) {
			var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
			str = str.replace(regEx, arguments[i]);
		}
		return str;
	}


};

formatter.sulemp = {
	cfdiName: comprobante => `${comprobante._Serie}-${comprobante._Folio}`
};

// exportar formateador
export default formatter;
