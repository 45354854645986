import React from 'react';

import {
    currency,
} from '../../Models';

const INIT_CONCEPTO = {
    _Producto: {

    }
}

export default ({ concepto = { ...INIT_CONCEPTO } , onCheck = () => {} }) => (
    <tr>
        {/* <td className="text--center"><input type="checkbox" checked={concepto.isSelected} onChange={onCheck}/></td> */}
        <td>{concepto._Cantidad}</td>
        <td className="text--left">{concepto._Producto._Descripcion}</td>
        <td className="text--right">{ currency( concepto._Importe ) }</td>
    </tr>
);