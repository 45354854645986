// export Validador from './Validador/Validador';
// export ToResult from './Services/ToResult';

import LocalStorage from './Services/LocalStorage';
import AmplifyHelperClass from './Services/AmplifyHelperClass';
import Formatter from './Services/Formatter';
import ToResultService from './Services/ToResult';

export const formatter = Formatter;
export const localStorage = new LocalStorage(new AmplifyHelperClass());
export const  ToResult = ToResultService;