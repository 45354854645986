import React from 'react';
import $ from 'jquery';
import AutoAffix from 'react-overlays/lib/AutoAffix';
import { localStorage } from '../../../Utils';

const LOGO_HAZTUFACTURA = 'assets/images/logo/logo-dark.png';

class Layout extends React.Component  {
    state = {

    }

    GetUrlInicio(){
        const empresa = localStorage.get('empresa');
        if( empresa && empresa._Configuracion ){
            return `#/${empresa._Configuracion._DatosVenta._ShortName}`;
        }
        return `#/`;
    }

    render(){
        const empresa = localStorage.get('empresa');
        $(document).ready(()=>{
            var $bgSection = $(".bg-section");
            if($bgSection){
                $bgSection.each(function() {
                    var bgSrc = $(this).children("img").attr("src");
                    var bgUrl = 'url(' + bgSrc + ')';
                    $(this).parent().css("backgroundImage", bgUrl);
                    $(this).parent().addClass("bg-section");
                    $(this).remove();
                });
            }
        });
        return (
            <div key="container-1">
                <AutoAffix 
                    key="afiaxed-1"
                    container={this} 
                    topClassName="affix-top"
                    affixClassName="affix">
                    <nav id="primary-menu" className="header header-1 header-transparent header-fixed navbar navbar-fixed-top">
                        <div className="container">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a href={ this.GetUrlInicio() } className="logo">
                                    <img className="logo-dark" height="60px" src={ ( empresa ? empresa._Logo: undefined )  || LOGO_HAZTUFACTURA } alt="Has tu factura"/>
                                </a>
                            </div>
                            <div className="collapse navbar-collapse pull-right" id="navbar-collapse-1">
                                <ul className="nav navbar-nav nav-pos-right navbar-left nav-split">
                                    <li className="active">
                                        <a href={ this.GetUrlInicio() }>Inicio</a>
                                    </li>
                                    {/*<li>
                                        <a href="#">Mis facturas</a>
                                    </li>
                                    <li>
                                       <a href="#">Ayuda</a>
                                    </li>*/}
                                    <li>
                                        <a href="#/">Salir</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </AutoAffix>
                <section id="slider" className="section slider slider-form" >
                    <div className="slide--item bg-overlay bg-overlay-dark">
                        <div className="bg-section">
                            <img src="assets/images/background/bg-5.jpg" alt="bg"/>
                        </div>
                        <div className="container">
                            <div className="row row-content" style={{ paddingBottom: '100px'}}>
                                { this.props.children }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Layout;