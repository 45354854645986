import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import Base from './Modules/Base';
import Reducers from './Modules/Base/Reducers';
const createStoreWithMiddleware = applyMiddleware()(createStore);

ReactDOM.render(
	<Provider store={createStoreWithMiddleware(Reducers)}>
		<Base />
	</Provider>,
	document.querySelector('#root')
);
