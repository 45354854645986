
class LocalStorage {
	constructor(dataSource) {
		this.dataSource = dataSource;
	}

	timeIn = {
		hours: (h) => (1000 * 3600) * h,
		minutes: (m) => (1000 * 60) * m,
		seconds: (s) => (1000) * s
	}
	// Obtener datos.
	get(key) {
		return this.dataSource.get(key);
	}
	// Obtener token.
	getToken() {
		const token = this.dataSource.get('TokenData');
		if (!token && window.location.pathname !== '/') return window.location = '/';
		return token;
	}
	// Obtener propiedad del token.
	getFromToken(key) {
		const token = this.getToken();

		return token ? token[key] : undefined;
	}
	// Obtener objeto del token.
	getObj(key) {
		const obj = this.get(key);
		if (obj)
			return obj;
		return {};
	}
	// Crear propiedad
	set(key,data) {
		this.lastModified = key;
		this.dataSource.set(key, data, this.time);
		return this;
	}
	createToken(data) {
		this.lastModified = 'TokenData';
		this.dataSource.set('TokenData', data);
		return this;
	}
	updateToken(data) {
		const token = this.getToken();
		const modified = this.lastModified ? this.lastModified : 'TokenData';
		this.set(modified, { ...token, ...data });
	}
	expiresIn(time) {
		this.time = time;

		const token = this.getToken();
		this.set('TokenData', token, time);
		return this;
	}
}
export default LocalStorage;
