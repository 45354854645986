export const empresas = {
	url_obtenerempresaporshortname: '/vnts/empresa/obtenerempresaporshortname'
};

export const receptores = {
	url_obtenerreceptordelemisor: '/cfdi/receptores/obtenerReceptorDelEmisor',
	url_crear: '/cfdi/receptores/crear'
}

export const sat = {
	url_pais: '/sat/SAT_Pais',
    url_estados: '/sat/SAT_Estados',
    url_codigopostal: '/sat/SAT_CodigoPostal',
    url_usocfdi: '/sat/SAT_UsoCFDI',
	url_formapago: '/sat/SAT_FormaPago',
	url_metodopago: '/sat/SAT_MetodoPago',
	url_monedas: '/sat/SAT_Moneda'
};

export const ventas = {
	url_ventaporfolio: '/vnts/ventas/obtenerventaporfolio',
	url_generartimbre: '/vnts/ventas/generartimbre',
};

export const cfdi = {
	url_datoscfdi: '/cfdi/datosventa',
	url_configuracion_rutas: '/cfdi/configuraciones/obtenerrutas',
	url_timbrar: '/cfdi/crearregresaobjeto',
	url_ver: '/cfdi/ObtenerPorId',
	url_enviar_correo_a: '/cfdi/mandarcorreoa',
	url_obtenercfdirelacionados:'/cfdi/obtenercfdirelacionados',
	url_obtenerconceptonotadecredito:'/cfdi/obtenerconceptonotadecredito',
	url_obtenerconceptoconsumo:'/cfdi/obtenerconceptoconsumo' 
};
